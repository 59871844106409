import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api/api";
// import { useAddonsFromUrl } from "../hooks/addonsFromUrl";
import useUrlQuery from "../hooks/useUrlQuery";
import {
  setFallbackToExpiry,
  setFormData,
  setNoNumberCarDetails,
} from "../pages/Landing/Landing.slice";
import {
  clearNotGenerated,
  setQuoteEndPoints,
} from "../pages/QuotesPage/QuotesPage.slice";
import { useVerifyQuery } from "./PosServices";
export const useBrandQuery = () => {
  const { data, isLoading, isError, error } = useQuery(
    "brands",
    async () => await api.post("api/GetBrands")
  );
  return {
    brandList: data?.data?.response || [],
    isLoading,
    isError,
    error,
  };
};
export const useModelQuery = (BRAND_ID) => {
  const { data, isLoading, isError, error } = useQuery(
    ["models", BRAND_ID],
    async () => await api.post("api/GetModels", { BRAND_ID })
  );

  return {
    modelList: data?.data?.response,
    isLoading,
    isError,
    error,
  };
};
export const useFuelQuery = (MODEL_ID) => {
  const { data, isLoading, isError, error } = useQuery(
    ["fuels", MODEL_ID],
    async () => await api.post("api/GetFuelTypes", { MODEL_ID })
  );

  return {
    fuelList: data?.data?.response?.TYPE_OF_FUEL || [],
    isLoading,
    isError,
    error,
  };
};
export const useVariantQuery = ({ MODEL_ID, TYPE_OF_FUEL }) => {
  const { data, isLoading, isError, error } = useQuery(
    ["variants", { MODEL_ID, TYPE_OF_FUEL }],
    async () => await api.post("api/GetVariants", { MODEL_ID, TYPE_OF_FUEL })
  );

  return {
    variantList: data?.data?.response || [],
    isLoading,
    isError,
    error,
  };
};
export const useRTOQuery = () => {
  const { data, isLoading } = useQuery(
    "RTO",
    async () => await api.post("api/RtoMaster")
  );

  return {
    rtoList: data?.data?.response.map((item) => ({
      label: item.rto_number + " " + item.rto_name,
      id: item.rto_number,
    })),
    isLoading,
  };
};
export const useGetCarDetailsMutation = () => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    (data) => {
      return api.post("/api/GetCarDetails", data);
    },
    {
      onSuccess: ({ data }) => {
        const {
          vehicle_make,
          vehicle_model,
          registration_date,
          vehicle_variant,
          vehicle_version_id,
          fuel_type,
          rto_name,
          model_id,
          brand_id,
          mobile_no,
          rto_number,
        } = data.response[0];
        dispatch(
          setNoNumberCarDetails({
            BRAND_TITLE: vehicle_make,
            MODEL_NAME: vehicle_model,
            MODEL_ID: model_id,
            BRAND_ID: brand_id,
            registration_year: registration_date.split("-")[0],
            VARIANT_NAME: vehicle_variant,
            VERSION_ID: vehicle_version_id,
            TYPE_OF_FUEL: fuel_type,
            rto_name,
            rto_id: rto_number,
          })
        );
        dispatch(setFallbackToExpiry(1));
      },
    }
  );
  return { getCarDetails: mutate };
};
export const useEnquiryMutation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const prevAddon = useAddonsFromUrl();
  const { user } = useVerifyQuery();
  const { set, get } = useUrlQuery();
  const { mutate, isLoading, isSuccess, data } = useMutation(
    (data) => {
      const {
        carDetails: {
          BRAND_TITLE,
          MODEL_NAME,
          TYPE_OF_FUEL,
          VARIANT_NAME,
          VERSION_ID,
          rto_name,
          registration_year,
          MODEL_ID,
          BRAND_ID,
        },
      } = data;
      const newData = {
        ...data.formData,
        vehicle_make: BRAND_TITLE,
        vehicle_model: MODEL_NAME,
        model_id: MODEL_ID,
        brand_id: BRAND_ID,
        fuel_type: TYPE_OF_FUEL,
        vehicle_variant: VARIANT_NAME,
        vehicle_version_id: VERSION_ID,
        rto_number: rto_name.split(" ")[0],
        rto_name: rto_name,
        vehicle_registration_year: registration_year,
        ...(user ? { role_id: user.ROLE_ID, user_id: user.USER_ID } : {}),
      };
      return api.post("api/GetEligibleProducts", { ...newData, lob: "car" });
    },
    {
      onSuccess: ({ data }) => {
        if (data.status === 500) {
          toast.error("Server Error");
          return;
        }
        navigate(`/quotes?${set("quote_id", data.response.quote_id)}`);
      },
    }
  );
  if (data?.data?.response.quote_end_points)
    dispatch(setQuoteEndPoints(data?.data?.response.quote_end_points));
  return {
    submitForm: mutate,
    quote_id: data?.data?.response?.quote_id,
    isLoading,
    isSuccess,
  };
};
export const useEnquiryQuery = () => {
  const { get } = useUrlQuery();
  const quote_id = get("quote_id");
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ["enquiry", quote_id],
    async () => await api.get(`/api/GetEligibleProductsById/${quote_id}`),
    {
      onSuccess: ({ data }) => {
        dispatch(clearNotGenerated());
        dispatch(setQuoteEndPoints(data.response.quote_end_points));
      },
    }
  );
  const req = data?.data?.request;
  const res = data?.data?.response;
  if (req) {
    const {
      vehicle_make,
      vehicle_model,
      registration_date,
      vehicle_variant,
      vehicle_version_id,
      fuel_type,
      rto_name,
      model_id,
      brand_id,
      mobile_no,
      is_claim_made_last_year,
      is_policy_expired,
      policy_expired_before_90_days,
      rto_number,
    } = req;
    dispatch(
      setNoNumberCarDetails({
        BRAND_TITLE: vehicle_make,
        MODEL_NAME: vehicle_model,
        MODEL_ID: model_id,
        BRAND_ID: brand_id,
        registration_year: registration_date.split("-")[0],
        VARIANT_NAME: vehicle_variant,
        VERSION_ID: vehicle_version_id,
        TYPE_OF_FUEL: fuel_type,
        rto_name,
        rto_id: rto_number,
      })
    );
    dispatch(
      setFormData({
        mobile_no,
        is_claim_made_last_year,
        is_policy_expired,
        policy_expired_before_90_days: policy_expired_before_90_days || "N",
      })
    );
  }

  return { requestData: req, responseData: res, isLoading };
};
