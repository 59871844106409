import React from "react";
import { Container, Row } from "react-bootstrap";
import {
  ButtonWrapper,
  HeaderWrap,
  Logo,
  Nav,
  NavbarCol,
  NavButton,
  NavLink,
  NavLinks,
} from "./Navbar.style";
import vkoverLogo from "./../../assets/images/vkover.png";
import nav_button from "./../../assets/images/nav_button.png";
import "styled-components/macro";
import { Button } from "../Button/Button";
import { useVerifyQuery } from "../../services/PosServices";
import { MenuItem, Avatar, Menu, Drawer, Box, Stack } from "@mui/material";
import { useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";

const Navbar = ({ showOnMobile }) => {
  const { isVerifying, isVerified, user } = useVerifyQuery();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [drawer, setDrawer] = React.useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <HeaderWrap id="navbarid" showOnMobile={showOnMobile}>
        <Container>
          <Row>
            <NavbarCol>
              <NavButton
                onClick={() => {
                  setDrawer(true);
                }}
              >
                <img src={nav_button} alt="nav-button"></img>
              </NavButton>
              <Drawer
                anchor="left"
                open={drawer}
                onClose={() => {
                  setDrawer(false);
                }}
                sx={{
                  "& .MuiDrawer-Paper": {
                    p: 2,
                  },
                }}
              >
                <Stack spacing={2} width={"200px"} px={2} mt={3}>
                  <NavLink href={process.env.REACT_APP_BASE_REDIRECT_URL}>
                    Home
                  </NavLink>
                  <NavLink
                    href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/about-us`}
                  >
                    About Us
                  </NavLink>
                  {!isVerified && (
                    <NavLink href="https://play.google.com/store/search?q=vkover&c=apps">
                      Join As POSP
                    </NavLink>
                  )}
                  <NavLink>Contact Us</NavLink>
                </Stack>
              </Drawer>
              <Logo>
                <img src={vkoverLogo} alt="brand-logo"></img>
              </Logo>
              <Nav>
                <NavLinks>
                  <NavLink href={process.env.REACT_APP_BASE_REDIRECT_URL}>
                    Home
                  </NavLink>
                  <NavLink
                    href={`${process.env.REACT_APP_BASE_REDIRECT_URL}/about-us`}
                  >
                    About Us
                  </NavLink>
                  {!isVerified && (
                    <NavLink href="https://play.google.com/store/search?q=vkover&c=apps">
                      Join As POSP
                    </NavLink>
                  )}
                  <NavLink>Contact Us</NavLink>
                </NavLinks>
                {!isVerified ? (
                  <ButtonWrapper>
                    <Button primary>Login</Button>
                    <Button
                      secondary
                      onClick={() => {
                        window.open(
                          "https://play.google.com/store/apps/details?id=in.immneos.vkover&hl=en_IN"
                        );
                      }}
                    >
                      Sign Up
                    </Button>
                  </ButtonWrapper>
                ) : (
                  <>
                    <button
                      css={`
                        background: none;
                        border: none;
                        display: inline-flex;
                        gap: 8px;
                        align-items: center;
                      `}
                      onClick={handleClick}
                    >
                      <Avatar>{user?.FIRST_NAME?.charAt(0)}</Avatar>
                      <div>{user?.EMAIL_ID}</div>
                    </button>
                    <UserMenu
                      anchorEl={anchorEl}
                      open={open}
                      handleClose={handleClose}
                    />
                  </>
                )}
              </Nav>
            </NavbarCol>
          </Row>
        </Container>
      </HeaderWrap>
    </>
  );
};
const UserMenu = ({ anchorEl, open, handleClose = () => {} }) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={() => {
          window.open("https://account.vkover.com/dashboard");
          handleClose();
        }}
      >
        My account
      </MenuItem>
      <MenuItem
        onClick={() => {
          window.open("https://account.vkover.com/logout", "_self");
          handleClose();
        }}
      >
        Logout
      </MenuItem>
    </Menu>
  );
};
export default Navbar;
