import React, { useEffect, useState } from "react";
import AutoCompleteSelect from "../../../../../../components/AutoCompleteSelect/AutoComplete";
import DatePicker from "../../../../../../components/DatePicker/DatePicker";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {
  useGetOccupationMasterQuery,
  useGetRelationMasterQuery,
  useProposalEnquiry,
} from "../../../../../../services/ProposalServices";
import { yesNoItems } from "../../../../../../utils/YesNoRadioItems";
import { InputWrapper } from "../../../../../QuotesPage/components/EditDetailsDialog/EditDetailsDialog.style";
import ClosedForm from "../ClosedForm/ClosedForm";
import "styled-components/macro";
import personalDetailsSchema from "./validationSchema";
import { onInputPhone, uppercase } from "../../../../../../utils/onInput";
import { useSelector } from "react-redux";
import moment from "moment";
import { SummaryDetailsWrapper } from "../../../../ProposalForm.style";
import Summary from "../../../Summary/Summary";
import getAge from "../../../../../../utils/getAge";
const genderOptions = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];

const PersonalDetails = () => {
  const { product } = useProposalEnquiry();
  const { relations, isLoading } = useGetRelationMasterQuery(
    product?.INSURANCE_SLUG
  );
  const { occupations, isLoading: isOccupationLoading } =
    useGetOccupationMasterQuery(product?.INSURANCE_SLUG);

  const { proposalData, activeIndex } = useSelector((state) => state.proposal);
  const [formData, setFormData] = useState({
    nominee_name: "",
    nominee_age: "",
    nominee_relation: "",
    gender: "M",
    is_married: "N",
    dob: "",
    nominee_dob: "",
  });

  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...proposalData }));
  }, [proposalData]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      ...proposalData,
      company_slug: product?.INSURANCE_SLUG,
    }));
  }, [product]);
  const [errors, setErrors] = useState();
  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    personalDetailsSchema
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = (onSuccess = () => {}) => {
    personalDetailsSchema
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData });
      })
      .catch((error) => {
        error.inner.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };

  const SummaryDetails = (
    <>
      <SummaryDetailsWrapper>
        {proposalData?.owner_type === "IND" && (
          <Summary
            label="Gender"
            value={formData.gender === "M" ? "Male" : "Female"}
          />
        )}
        {<Summary label="Nominee Name" value={formData.nominee_name} />}
        {proposalData?.owner_type === "IND" && (
          <Summary
            label="Married"
            value={formData.is_married === "Y" ? "Yes" : "No"}
          />
        )}
        {
          <Summary
            label={"Nominee's Date of Birth"}
            value={formData.nominee_dob.split("-").reverse().join("-")}
          />
        }
        {proposalData?.owner_type === "IND" && (
          <Summary
            label="Date of Birth"
            value={formData.dob.split("-").reverse().join("-")}
          />
        )}
        {
          <Summary
            label="Nominee Relationship"
            value={
              relations?.find(
                (item) => item.OPTION_KEY === formData?.nominee_relation
              )?.OPTION_VAL
            }
          />
        }
      </SummaryDetailsWrapper>
    </>
  );

  return (
    <>
      <ClosedForm
        title="2. Personal Details"
        next={"Communication Address"}
        index={1}
        onSubmit={onSubmit}
      >
        {activeIndex > 1 ? (
          SummaryDetails
        ) : (
          <>
            {proposalData?.owner_type === "IND" && (
              <>
                {" "}
                <InputWrapper>
                  <RadioButton
                    label={"Gender"}
                    items={genderOptions}
                    defaultValue={formData?.gender}
                    onChange={(val) => onChange("gender", val)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <RadioButton
                    label={"Married"}
                    items={yesNoItems}
                    defaultValue={formData?.is_married}
                    onChange={(val) => onChange("is_married", val)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    label={"Date of Birth"}
                    size="medium"
                    maxDate={moment(moment().subtract(18, "years"))}
                    minDate={moment(moment().subtract(60, "years"))}
                    handleChange={(val) => {
                      onChange("dob", val);
                    }}
                    readOnly={proposalData?.ckyc}
                    outputFormat="YYYY-MM-DD"
                    value={formData?.dob}
                    error={errors?.dob}
                  />
                </InputWrapper>
                <InputWrapper>
                  <AutoCompleteSelect
                    options={occupations}
                    _key={"OPTION_KEY"}
                    loading={isOccupationLoading}
                    selectedValue={
                      formData?.occupation && {
                        OPTION_KEY: formData?.occupation,
                        OPTION_VAL:
                          formData?.occupation &&
                          occupations?.find(
                            (item) => item.OPTION_KEY === formData?.occupation
                          )?.OPTION_VAL,
                      }
                    }
                    getOptionLabel={(item) => item.OPTION_VAL || ""}
                    getOptionSelected={(item, val) =>
                      item.OPTION_KEY === val.OPTION_KEY
                    }
                    label={"Occupation"}
                    size="medium"
                    error={errors?.occupation}
                    handleChange={(val) => {
                      onChange("occupation", val.OPTION_KEY);
                    }}
                  />
                </InputWrapper>
                <InputWrapper>
                  <TextInput
                    label={"Nominee Name"}
                    size="medium"
                    onInput={(e) =>
                      (e.target.style.textTransform = "capitalize")
                    }
                    onChange={(e) => onChange("nominee_name", e.target.value)}
                    value={formData?.nominee_name}
                    error={errors?.nominee_name}
                  />
                </InputWrapper>
                <InputWrapper>
                  <DatePicker
                    label={"Nominee's Date of Birth"}
                    size="medium"
                    maxDate={moment(moment().subtract(18, "years"))}
                    minDate={moment(moment().subtract(60, "years"))}
                    handleChange={(val) => {
                      onChange("nominee_dob", val);
                      onChange("nominee_age", getAge(val));
                    }}
                    outputFormat="YYYY-MM-DD"
                    value={formData?.nominee_dob}
                    error={errors?.nominee_dob}
                  />
                </InputWrapper>
                <InputWrapper>
                  <AutoCompleteSelect
                    options={relations}
                    _key={"OPTION_KEY"}
                    loading={isLoading}
                    selectedValue={
                      formData?.nominee_relation && {
                        OPTION_KEY: formData?.nominee_relation,
                        OPTION_VAL:
                          formData?.nominee_relation &&
                          relations?.find(
                            (item) =>
                              item.OPTION_KEY === formData?.nominee_relation
                          )?.OPTION_VAL,
                      }
                    }
                    getOptionLabel={(item) => item.OPTION_VAL || ""}
                    getOptionSelected={(item, val) =>
                      item.OPTION_KEY === val.OPTION_KEY
                    }
                    label={"Nominee Relationship"}
                    size="medium"
                    error={errors?.nominee_relation}
                    handleChange={(val) => {
                      onChange("nominee_relation", val.OPTION_KEY);
                    }}
                  />
                </InputWrapper>
              </>
            )}
          </>
        )}
      </ClosedForm>
    </>
  );
};

export default PersonalDetails;
