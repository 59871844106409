import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useProposalEnquiry } from "../../../../../../services/ProposalServices";

const BackButton = ({ seeMore }) => {
  const navigate = useNavigate();
  const { proposal } = useProposalEnquiry();
  return (
    <>
      <Button
        onClick={() =>
          navigate({
            pathname: "/quotes",
            search: `?quote_id=${proposal?.QUOTE_ID}&stage=PF`,
          })
        }
        seeMore={seeMore}
      >
        <span>
          <i className="fas fa-long-arrow-alt-left"></i>
        </span>
        Change Insurer
      </Button>
    </>
  );
};

export default BackButton;
const Button = styled.button`
  & span {
    margin-right: 10px;
  }
  font-size: 18px;
  font-weight: 600;
  align-self: flex-start;
  text-align: left;
  width: unset;
  background: #006f8f;
  border: none;
  color: white;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    font-size: 16px;
    padding: 0;
    margin-bottom: 12px;
    & span {
      display: ${(props) => !props.seeMore && "none"};
    }
  }
`;
