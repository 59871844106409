import React from "react";
import { Container } from "react-bootstrap";
import {
  Card,
  ColumnWrapper,
  Download,
  InfoText,
  MainText,
  MainWrapper,
  RatingsCard,
  RatingsWrapper,
  Retry,
  StatusIcon,
} from "./ThankYou.style";
import success from "./../../assets/images/success.svg";
import cancel from "./../../assets/images/cancel.png";
import RatingCard from "./components/RatingCard";
import {
  useGetDocument,
  useStatusQuery,
} from "../../services/ThankYouServices";
import CheckMark from "./components/CheckMark";

const ThankYou = () => {
  const { status, isLoading } = useStatusQuery();

  const { data, refetch } = useGetDocument();
  if (isLoading) return <></>;
  const {
    PROPOSAL_NUMBER,
    POLICY_NUMBER,
    PAYMENT_STATUS,
    POLICY_DOC,
    KYC_URL,
  } = status;
  const isSuccess = PAYMENT_STATUS === "SUCCESS";
  return (
    <>
      <Container>
        <Card>
          <ColumnWrapper>
            {isSuccess ? <CheckMark /> : <StatusIcon src={cancel} />}
            <MainWrapper>
              <MainText primary>
                {isSuccess ? "Congratulations !" : "Sorry !"}
              </MainText>
              <MainText primary>
                {isSuccess
                  ? "Your Payment has been processed successfully."
                  : "Your Payment has failed."}
              </MainText>
            </MainWrapper>
            {isSuccess ? (
              <InfoText>
                Your policy no. <span>{POLICY_NUMBER}</span> has been generated
                and the insurance company may contact you for additional
                documents. Please feel free to reach out to your Digital
                Relationship Manager at info@vkover.com or call us on our no.
                9946415551 and we will take care of all your policy related
                servicing request and claims, if any.
              </InfoText>
            ) : (
              <InfoText>
                Please feel free to reach out to your Digital Relationship
                Manager at info@vkover.com or call us on our <br />
                no. 9946415551 and we will take care of all your policy related
                servicing request and claims, if any.
              </InfoText>
            )}
            {KYC_URL && (
              <Retry onClick={() => window.open(KYC_URL, "_blank")}>
                Retry KYC
              </Retry>
            )}
            {isSuccess &&
              !KYC_URL &&
              (!POLICY_DOC && !data ? (
                <>
                  <Retry onClick={() => refetch()}>Retry Document</Retry>
                </>
              ) : (
                <Download href={POLICY_DOC || data} target={"_blank"} download>
                  Download Policy Document{" "}
                  <i className="fas fa-arrow-circle-down"></i>
                </Download>
              ))}
          </ColumnWrapper>
        </Card>
        <Card>
          <ColumnWrapper>
            <MainText>Help Us Help You & Enjoy A Better Experience</MainText>
            <RatingsWrapper>
              <RatingCard label={"Overall Experience"} />
              <RatingCard label={"Easy To Buy"} />
              <RatingCard label={"Recommend Us"} />
              <RatingCard label={"Customer support experience"} />
            </RatingsWrapper>
          </ColumnWrapper>
        </Card>
      </Container>
    </>
  );
};

export default ThankYou;
