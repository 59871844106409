export const onInputName = (e) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/[^A-Za-z]/g, "");
  e.target.value =
    e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
};
export const lowercase = (e) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.toLowerCase();
};
export const onInputPhone = (e) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const uppercase = (e) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.toUpperCase();
};
export const noSpace = (e) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/\s/g, "");
};
export const noSpecialExceptHyphen = (e) => {
  if (!e.target.value) return;
  e.target.value = e.target.value.replace(/[^A-Za-z0-9\-]/g, "");
};
export const startsWithNum = (v) => {
  if (!v) return true;

  let match = v.match(/\d/);
  return match && match.index === 0;
};
export const startsWithAlpha = (v) => {
  if (!v) return false;
  let match = v.match(/[A-Za-z]/);
  return match && match.index === 0;
};
export const indexNum = (v) => {
  if (!v) return 0;

  let match = v.match(/\d/);
  return match && match.index;
};
export const indexAlpha = (v) => {
  if (!v) return true;
  return v.match(/\d/).index === 0;
};
export const allowRegistrationNumber = (e) => {
  e.preventDefault();
  let input = e.target.value;
  let splitInput = input.split("-");
  let values = splitInput.map(function (v, i) {
    if (i === 0) {
      if (v.length > 2) return v.substr(0, 2);
      return v.replace(/[^A-Za-z]/g, "").toUpperCase();
    }
    if (i === 1) {
      if (v.length > 2) return v.substr(0, 2);
      return v.replace(/[^0-9]/g, "").toUpperCase();
    }
    if (i === 2 && startsWithNum(v)) {
      if (v.length > 4) return v.substr(0, 4);
      return v.replace(/[^0-9]/g, "");
    }
    if (i === 2 && startsWithAlpha(v)) {
      if (indexNum(v) < 1 && indexNum(v)) return v.substr(0, 1);
      return v.toUpperCase();
    }
    if (i === 3) {
      if (v.length >= 4) return v.substr(0, 4);
      return v.replace(/[^0-9]/g, "");
    }
    return v;
  });
  let output = values.map((v, i) => {
    if ((i === 0 || i === 1) && v.length === 2) return v + "-";
    if (i === 2 && startsWithAlpha(v)) {
      if (v.length === 3 && !indexNum(v)) return v + "-";
      if (indexNum(v) >= 1) {
        return v.slice(0, indexNum(v)) + "-" + v.slice(indexNum(v));
      }
      if (values[3]) {
        return v + "-";
      }
    }
    if (i === 3 && v) return v;
    return v;
  });
  e.target.value = output.join("");
};
