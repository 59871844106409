import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../../../../api/api";
import AutoCompleteSelect from "../../../../../../components/AutoCompleteSelect/AutoComplete";
import RadioButton from "../../../../../../components/RadioButton/RadioButton";
import TextInput from "../../../../../../components/TextInput/TextInput";
import {
  useGetPincodeMutation,
  useProposalEnquiry,
} from "../../../../../../services/ProposalServices";
import { onInputPhone } from "../../../../../../utils/onInput";
import { InputWrapper } from "../../../../../QuotesPage/components/EditDetailsDialog/EditDetailsDialog.style";
import { SummaryDetailsWrapper } from "../../../../ProposalForm.style";
import Summary from "../../../Summary/Summary";
import ClosedForm from "../ClosedForm/ClosedForm";
import communicationAddressSchema, {
  relianceCommunicationAddressSchema,
} from "./validationSchema";

const CommunicationAddress = () => {
  const { proposalData, activeIndex } = useSelector((state) => state.proposal);

  const [errors, setErrors] = useState();
  useEffect(() => {
    setFormData((prev) => ({ ...prev, ...proposalData }));
  }, [proposalData]);
  const { proposal, product } = useProposalEnquiry();
  const isReliance = product?.INSURANCE_SLUG === "reliance";
  const [formData, setFormData] = useState({
    address: "",
    pincode: "",
    city_val: "",
    city_name: "",
    is_reg_same_as_com: "Y",
  });
  const onChange = async (name, data) => {
    setFormData((prev) => ({ ...prev, [name]: data }));
    (isReliance
      ? relianceCommunicationAddressSchema
      : communicationAddressSchema
    )
      .validateAt(name, {
        ...formData,
        [name]: data,
      })
      .then(() => {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      })
      .catch((error) => {
        setErrors((prev) => ({ ...prev, [name]: error.message }));
      });
  };
  const onSubmit = (onSuccess = () => {}) => {
    (isReliance
      ? relianceCommunicationAddressSchema
      : communicationAddressSchema
    )
      .validate(formData, { abortEarly: false })
      .then(() => {
        setErrors({});
        onSuccess({ ...proposalData, ...formData });
      })
      .catch((error) => {
        error.inner.forEach((item) => {
          setErrors((prev) => ({ ...prev, [item.path]: item.message }));
        });
      });
  };
  useEffect(() => {
    if (formData?.is_reg_same_as_com === "Y") {
      setFormData((prev) => ({
        ...prev,
        ...{
          reg_address: formData.address,
          reg_pincode: formData.pincode,
          reg_city_val: formData.city_val,
          reg_city_name: formData.city_name,
          reg_state_name: formData.state_name,
          reg_state_val: formData.state_val,
        },
      }));
    }
  }, [
    formData?.is_reg_same_as_com,
    formData?.address,
    formData?.pincode,
    formData?.city_val,
    formData?.city_name,
    formData?.state_name,
    formData?.state_val,
  ]);
  const [cityOptions, setCityOptions] = useState();
  const [regCityOptions, setRegCityOptions] = useState();

  useEffect(() => {
    if (formData?.pincode.length === 6) {
      api
        .post("api/GetPincodeMaster", {
          pincode: formData?.pincode,
          company_alias: proposal?.COMPANY_SLUG,
        })
        .then(({ data }) => {
          const res = data.response;
          setCityOptions(res.city);
          setFormData((prev) => ({
            ...prev,
            state_name: res.state[0].OPTION_VAL,
            state_val: res.state[0].OPTION_KEY,
          }));
        })
        .catch((err) => console.error(err));
    }
  }, [formData?.pincode]);
  useEffect(() => {
    if (formData?.reg_pincode?.length === 6) {
      api
        .post("api/GetPincodeMaster", {
          pincode: formData?.reg_pincode,
          company_alias: proposal?.COMPANY_SLUG,
        })
        .then(({ data }) => {
          const res = data.response;
          setRegCityOptions(res.city);
          setFormData((prev) => ({
            ...prev,
            reg_state_name: res.state[0].OPTION_VAL,
            reg_state_val: res.state[0].OPTION_KEY,
          }));
        })
        .catch((err) => console.error(err));
    }
  }, [formData?.reg_pincode]);
  const SummaryDetails = (
    <>
      <SummaryDetailsWrapper>
        <Summary label="Communication Address" value={formData.address} />
        <Summary label="Pincode" value={formData.pincode} />
        <Summary label="City" value={formData.city_name} />
        <Summary label="State" value={formData.state_name} />
        {isReliance && (
          <>
            <Summary
              label="Is Registered Address Same as Communication Address"
              value={formData.is_reg_same_as_com === "Y" ? "Yes" : "No"}
              isFullWidth
            />
            {formData.is_reg_same_as_com === "N" && (
              <>
                <Summary
                  label="Registered Address"
                  value={formData.reg_address}
                />
                <Summary label="Pincode" value={formData.reg_pincode} />
                <Summary label="City" value={formData.reg_city_name} />
                <Summary label="State" value={formData.reg_state_name} />
              </>
            )}
          </>
        )}
      </SummaryDetailsWrapper>
    </>
  );

  return (
    <>
      <ClosedForm
        title={
          proposalData?.owner_type === "COMP"
            ? "2. Communication Address"
            : "3. Communication Address"
        }
        next={"Vehicle Details"}
        index={proposalData?.owner_type === "COMP" ? 1 : 2}
        onSubmit={onSubmit}
      >
        {activeIndex > (proposalData?.owner_type === "COMP" ? 1 : 2) ? (
          SummaryDetails
        ) : (
          <>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={"Communication Address"}
                multiline
                rows={5}
                onChange={(e) => onChange("address", e.target.value)}
                value={formData?.address}
                error={errors?.address}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                size={"medium"}
                label={"Area Pincode"}
                onChange={(e) => {
                  onChange("pincode", e.target.value);
                }}
                onInput={onInputPhone}
                maxLength={6}
                value={formData?.pincode}
                error={errors?.pincode}
              />
            </InputWrapper>
            <InputWrapper>
              <AutoCompleteSelect
                size={"medium"}
                label={"City"}
                options={cityOptions || []}
                _key={"OPTION_KEY"}
                getOptionLabel={(item) => item?.OPTION_VAL || ""}
                error={errors?.city}
                selectedValue={
                  formData?.city_val && {
                    OPTION_KEY: formData?.city_val,
                    OPTION_VAL: formData?.city_name,
                  }
                }
                getOptionSelected={(item, value) =>
                  item?.OPTION_KEY === value.OPTION_KEY
                }
                handleChange={(val) => {
                  onChange("city_val", val.OPTION_KEY);
                  onChange("city_name", val.OPTION_VAL);
                }}
              />
            </InputWrapper>
            <InputWrapper>
              <TextInput
                size={"medium"}
                value={formData?.state_name}
                label={"State"}
              />
            </InputWrapper>
            {isReliance && (
              <>
                <InputWrapper>
                  <RadioButton
                    items={[
                      { label: "Yes", value: "Y" },
                      { label: "No", value: "N" },
                    ]}
                    label="Is registration address same as communication address"
                    onChange={(val) => {
                      onChange("is_reg_same_as_com", val);
                    }}
                    defaultValue={formData?.is_reg_same_as_com}
                  />
                </InputWrapper>
                {formData?.is_reg_same_as_com === "N" && (
                  <>
                    {" "}
                    <InputWrapper>
                      <TextInput
                        size={"medium"}
                        label={"Registration Address"}
                        multiline
                        rows={5}
                        onChange={(e) =>
                          onChange("reg_address", e.target.value)
                        }
                        value={formData?.reg_address}
                        error={errors?.reg_address}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <TextInput
                        size={"medium"}
                        label={"Area Pincode"}
                        onChange={(e) => {
                          onChange("reg_pincode", e.target.value);
                          onChange("reg_city_val", "");
                          onChange("reg_city_name", "");
                          onChange("reg_city_val", "");
                          onChange("reg_state_val", "");
                          onChange("reg_state_name", "");
                        }}
                        onInput={onInputPhone}
                        maxLength={6}
                        value={formData?.reg_pincode}
                        error={errors?.reg_pincode}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <AutoCompleteSelect
                        size={"medium"}
                        label={"City"}
                        options={regCityOptions || []}
                        _key={"OPTION_KEY"}
                        getOptionLabel={(item) => item?.OPTION_VAL || ""}
                        error={errors?.reg_city}
                        selectedValue={
                          formData?.reg_city_val && {
                            OPTION_KEY: formData?.reg_city_val,
                            OPTION_VAL: formData?.reg_city_name,
                          }
                        }
                        getOptionSelected={(item, value) =>
                          item?.OPTION_KEY === value.OPTION_KEY
                        }
                        handleChange={(val) => {
                          onChange("reg_city_val", val.OPTION_KEY);
                          onChange("reg_city_name", val.OPTION_VAL);
                        }}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <TextInput
                        size={"medium"}
                        value={formData?.reg_state_name}
                        label={"State"}
                      />
                    </InputWrapper>
                  </>
                )}
              </>
            )}
          </>
        )}
      </ClosedForm>
    </>
  );
};

export default CommunicationAddress;
