import { Divider } from "@material-ui/core";
import React from "react";
import "styled-components/macro";
import formatPrice from "../../../../../../utils/formatPrice";
import { Main, MainWrapper } from "../../PolicyDetails.style";
import Details, { MainDetails } from "./components/Details";
import Section from "./components/Section";
import { useEnquiryQuery } from "./../../../../../../services/CarServices";
const getAddonsPremium = (ADDONS, gst) => {
  if (ADDONS && ADDONS.length)
    return ADDONS.reduce(
      (prev, curr) =>
        prev +
        Number(
          curr.premium === "Free"
            ? "0"
            : Math.round(curr.premium * (gst ? 0.18 / 1.18 : 1))
        ),
      0
    );
  return 0;
};
const PremiumBreakup = ({ addons, premiumData, productData }) => {
  const isCOMP = productData.POLICY_TYPE === "COMP";
  const { requestData } = useEnquiryQuery();
  const uniqueAddons = (addons) => {
    const seen = new Set();
    return addons.filter((addon) => {
      const isDuplicate = seen.has(addon.slug);
      seen.add(addon.slug);
      return !isDuplicate;
    });
  };
  return (
    <MainWrapper>
      <Main>
        <Section title={"Basic Cover"}>
          <Details
            label={"Basic OD Premium (A)"}
            value={`₹ ${formatPrice(premiumData?.BASIC_OD)}`}
          />
          <Details
            label={"Basic TP Premium (B)"}
            value={`₹ ${formatPrice(premiumData?.BASIC_TP)}`}
          />
          {premiumData?.NCB_DISCOUNT && premiumData?.NCB_DISCOUNT !== "0" && (
            <Details
              label={"NCB Discount (C)"}
              value={`₹ ${formatPrice(premiumData?.NCB_DISCOUNT)}`}
            />
          )}
          {/* <Details
            label={`Base Premium`}
            value={`₹ ${formatPrice(premiumData?.NET_PREMIUM)}`}
          /> */}
        </Section>
        {addons.length || requestData.fuel_type === "CNG" ? (
          <Section title={"Addons"}>
            {premiumData?.ADDONS?.CAN_OPT?.BIFUEL_OD !== "0" &&
              productData?.INSURANCE_SLUG === "reliance" && (
                <>
                  <Details
                    label={`${
                      requestData?.fuel_type === "CNG" ? "Internal" : "External"
                    } BI Fuel (OD) ${
                      requestData?.fuel_type === "CNG" ? "(Included)" : ""
                    }`}
                    value={`₹ ${formatPrice(
                      premiumData?.ADDONS?.CAN_OPT?.BIFUEL_OD / 1.18
                    )}`}
                  />
                </>
              )}
            {premiumData?.ADDONS?.CAN_OPT?.BIFUEL_TP !== "0" &&
              productData?.INSURANCE_SLUG === "reliance" && (
                <>
                  {" "}
                  <Details
                    label={`${
                      requestData?.fuel_type === "CNG" ? "Internal" : "External"
                    } BI Fuel (TP) ${
                      requestData?.fuel_type === "CNG" ? "(Included)" : ""
                    }`}
                    value={`₹ ${formatPrice(
                      premiumData?.ADDONS?.CAN_OPT?.BIFUEL_TP / 1.18
                    )}`}
                  />
                </>
              )}
            {uniqueAddons(addons).map((item) => {
              if (
                productData?.INSURANCE_SLUG === "reliance" &&
                item.slug === "lpg_cng_si"
              )
                return <></>;
              return (
                <Details
                  label={item.name}
                  value={
                    item.premium === "Free"
                      ? "-"
                      : `₹ ${formatPrice(item.premium / 1.18)}`
                  }
                />
              );
            })}
          </Section>
        ) : (
          <></>
        )}
        {/* <Section title={"Discounts"}>
          <Details label={"Basic OD Premium"} value={"2,050"} />
        </Section> */}
        <Section title={"Taxes"}>
          <Details
            label={"GST"}
            value={`₹ ${formatPrice(
              Number(premiumData?.TOTAL_PREMIUM - premiumData?.NET_PREMIUM) +
                getAddonsPremium(addons, true)
            )}`}
          />
        </Section>
        <Divider />
        <MainDetails
          label={"Amount Payable"}
          value={`₹ ${formatPrice(
            Number(premiumData?.TOTAL_PREMIUM) + getAddonsPremium(addons)
          )}`}
        />
      </Main>
    </MainWrapper>
  );
};

export default PremiumBreakup;
