import {
  capitalize,
  Dialog,
  Divider,
  Tab,
  Tabs,
  useMediaQuery,
} from "@material-ui/core";
import {
  CloseButton,
  DialogTitle,
} from "./../EditDetailsDialog/EditDetailsDialog.style";
import React, { useState } from "react";
import { Close } from "@material-ui/icons";
import { Wrapper } from "./PolicyDetails.style";
import PlanSummary from "./components/PlanSummary/PlanSummary";
import "styled-components/macro";
import { styled } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import PremiumBreakup from "./components/PremiumBreakup/PremiumBreakup";
import PlanDetails from "./components/PlanDetails/PlanDetails";
import CashlessGarages from "./components/CashlessGarages/CashlessGarages";
import Accordian from "./components/Accordian/Accordian";

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}
const PolicyDetails = ({
  open,
  onClose,
  productData,
  premiumData,
  addons,
  additional_premium,
  included,
}) => {
  const fullScreen = useMediaQuery("(max-width:576px)");
  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setValue(isExpanded ? panel : false);
  };
  return (
    <>
      <Dialog
        open={open}
        fullWidth
        onClose={onClose}
        maxWidth={"md"}
        fullScreen={fullScreen}
        disableEnforceFocus
      >
        <DialogTitle>
          <div>Policy Details</div>
          <CloseButton onClick={() => onClose()}>
            <Close />
          </CloseButton>
        </DialogTitle>
        <Divider />
        <Wrapper>
          <PlanSummary
            productData={productData}
            premiumData={premiumData}
            opted={addons}
            additional_premium={additional_premium}
            included={included}
          />
          {!fullScreen ? (
            <TabContext value={value}>
              <div
                css={`
                  width: 74%;
                `}
              >
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  variant={"fullWidth"}
                >
                  <StyledTab
                    label="Premium Breakup"
                    value="1"
                    {...a11yProps(0)}
                  />
                  <StyledTab label="Plan Details" value="2" {...a11yProps(1)} />
                  {premiumData?.IS_GARAGES_AVAILABLE === "Y" && (
                    <StyledTab
                      label="Cashless Garages"
                      value="3"
                      {...a11yProps(2)}
                    />
                  )}
                </StyledTabs>
                <div>
                  <StyledTabPanel value="1">
                    <PremiumBreakup
                      addons={[...addons, ...included]}
                      premiumData={premiumData}
                      productData={productData}
                    />
                  </StyledTabPanel>
                  <StyledTabPanel value="2">
                    <PlanDetails />
                  </StyledTabPanel>
                  {premiumData?.IS_GARAGES_AVAILABLE === "Y" && (
                    <StyledTabPanel value="3">
                      <CashlessGarages productData={productData} />
                    </StyledTabPanel>
                  )}
                </div>
              </div>
            </TabContext>
          ) : (
            <div
              css={`
                padding: 0 8px;
              `}
            >
              <Accordian
                panel="1"
                expanded={value === "1"}
                handleChange={handleChangeAcc}
                title="Premium Breakup"
              >
                <PremiumBreakup addons={addons} premiumData={premiumData} />
              </Accordian>
              <Accordian
                panel="2"
                expanded={value === "2"}
                handleChange={handleChangeAcc}
                title="Plan Details"
              >
                <PlanDetails />
              </Accordian>
              {premiumData?.IS_GARAGES_AVAILABLE === "Y" && (
                <Accordian
                  panel="3"
                  expanded={value === "3"}
                  handleChange={handleChangeAcc}
                  title="Cashless Garages"
                >
                  <CashlessGarages productData={productData} />
                </Accordian>
              )}
            </div>
          )}
        </Wrapper>
      </Dialog>
    </>
  );
};

export default PolicyDetails;
const StyledTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    width: "100%",
    backgroundColor: "var(--primary-color)",
    height: "4px",
  },
});
const StyledTab = styled(Tab)({
  fontSize: "18px",
  fontWeight: 600,
  textTransform: "capitalize",
  height: "60px",
  border: "1px solid #E5E5E5;",
  color: "var(--dark-grey-1)",
  "&.Mui-selected": {
    color: "var(--primary-color)",
  },
});
const StyledTabPanel = styled(TabPanel)({
  padding: 0,
});
